import React from 'react';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import { titles } from '../../../const/metaData';
import {
  Wrapper,
  TextContainer,
  BoldText,
  TableCell,
} from '../../../styles/commonStyles';

const PrivacyFull: React.FunctionComponent = () => {
  return (
    <Layout>
      <SEO title={titles.privacy} />
      <Wrapper>
        <TextContainer>
          <h2>Bó Privacy Notice</h2>
          <BoldText>1. Who are we</BoldText>
          <p>
            This privacy notice (the “Privacy Notice”) applies to all personal
            information Bó uses. Bó is part of the National Westminster Bank Plc
            (“NatWest”), which is part of The Royal Bank of Scotland Group plc
            (“RBS group”) and our principal address is 250 Bishopsgate, London
            EC2M 4AA. We’re a companion bank account here to help you spend
            smarter, save more and manage your money better. This Privacy Notice
            explains how we collect and use your personal information to provide
            you with our products and services.
          </p>
          <p>
            Keeping your personal information secure and safeguarding your
            privacy are really important to us. So is making sure you understand
            what personal information we use, and how we use it, where we get it
            from, who we share it with and what rights you have to control how
            we use it.
          </p>
          <p>
            From time to time we may need to update this Privacy Notice. When we
            do, we’ll let you know and publish the updated version in the Bó app
            and website. It’s a good idea to read the Privacy Notice every time
            we make changes to it so you know exactly how we use your data and
            what your rights are.
          </p>
          <BoldText>2. How we use your personal information</BoldText>
          <p>We use your personal information in three ways:</p>
          <ol>
            <li>To provide our products and services to you</li>
            <li>To meet our legal obligations</li>
            <li>To run our business</li>
          </ol>
          <p>
            We have described these in greater detail in Schedule A - What we
            use your information for
          </p>
          <BoldText>3. The information we use and where it comes from</BoldText>
          <p>
            We collect and use various categories of personal information but
            only to the extent we need to achieve one or more of the purposes
            listed above. This personal information includes:
          </p>
          <ul>
            <li>
              Personal information you supply through the Bó app or website (for
              example, when you signed up to Bó or when you use your account, or
              get in touch with us). This includes your name, contact details,
              ID and visual images (such as selfie or copy of passport photo).
            </li>
            <li>
              Transactional and other information we learn from how you use the
              Bó app, your card, your account, any RBS group accounts you hold,
              and any other third party bank accounts you’ve allowed us to
              access through account aggregation / Open Banking.
            </li>
            <li>
              Information we receive from third parties, such as those providing
              services to us or you e.g. ID verification services, credit
              reference agencies, fraud prevention or government agencies, and
              other banks.
            </li>
            <li>
              Device information including your location, mobile phone network,
              IP address and telephone number and how you use your mobile to
              access Bó
            </li>
            <li>
              Information about your family, lifestyle and social circumstances
              (such as dependents, marital status, next of kin and their contact
              details);
            </li>
            <li>
              Information about your financial circumstances, including personal
              wealth, assets and liabilities, proof of income and expenditure,
              credit and borrowing history and needs and goals
            </li>
            <li>
              Online profile and social media information and activity, based on
              yourinteraction with us and our websites and app, including for
              example, yourbanking profile and login information, Internet
              Protocol (IP) address, smart device information, location
              coordinates, online and mobile bankingsecurity authentication,
              mobile phone network information, searches, sitevisits and
              spending patterns.
            </li>
            <li>
              Information from publicly available sources including social media
              profiles, the electoral register, the media and online search
              engines.
            </li>
          </ul>
          <p>
            We may also use certain special categories of information for
            specific and limited purposes, such as detecting and preventing
            financial crime or to make our services accessible to customers. We
            will only use special categories of information where we’ve obtained
            your explicit consent or are otherwise lawfully permitted (and then
            only for the particular purposes and activities set out in Schedule
            A for which the information is provided). This may include:
          </p>
          <ul>
            <li>information about racial or ethnic origin,</li>
            <li>religious or philosophical beliefs;</li>
            <li>trade union membership;</li>
            <li>
              physical or psychological health details or medical conditions;
              andbiometric information, relating to the physical, physiological
              or behavioural characteristics of a person, including, for
              example, using voice recognitionor similar technologies to help us
              prevent fraud and money laundering.
            </li>
          </ul>
          <p>
            Where permitted by law, we may use information about criminal
            convictions or offences and alleged offences for specific and
            limited activities and purposes, such as to perform checks to
            prevent and detect crime and to comply with laws relating to money
            laundering, fraud, terrorist financing, bribery and corruption, and
            international sanctions. It may involve investigating and
            gatheringintelligence on suspected financial crimes, fraud and
            threats and sharing data between banks and with law enforcement and
            regulatory bodies.
          </p>
          <p>
            Your transaction history and account information may also contain
            special categories of personal data. For example, if you have a
            payment for a membership to a particular political party, this could
            reveal your political beliefs. We will not profile you on the basis
            of this data or otherwise use this data for any other purposes other
            than providing our services.
          </p>
          <BoldText>4. Your rights</BoldText>
          <p>
            Your rights in relation to the personal information we hold on you
            are set out in the table below. If you wish to exercise any of these
            rights, or if you have any queries about how we use your personal
            information which is not answered here, you can contact us at
            <a href="mailto:bosupport@natwest.com">BoSupport@natwest.com</a>
          </p>
          <p>
            Please note that in some cases, if you don’t agree with how we use
            your information, it may not be possible for us to continue to
            operate your Bó account and/or provide certain products and services
            to you.
          </p>
          <table>
            <tr>
              <TableCell header>Rights</TableCell>
              <TableCell header>Description</TableCell>
            </tr>
            <tr>
              <TableCell>Access</TableCell>
              <TableCell>
                You have the right to a copy of the personal information we hold
                on you andcan request it by contacting us at{' '}
                <a href="mailto:bosupport@natwest.com">BoSupport@natwest.com</a>
              </TableCell>
            </tr>
            <tr>
              <TableCell>Erasure</TableCell>
              <TableCell>
                You have the right to request we delete your personal
                information if you believe that:
                <ul>
                  <li>
                    we no longer need to use it for the purposes for which it
                    was provided;
                  </li>
                  <li>
                    you wish to withdraw your consent and we have no other
                    lawful basis touse the data; or
                  </li>
                  <li>we are not using your information in a lawful manner.</li>
                </ul>
              </TableCell>
            </tr>
            <tr>
              <TableCell>Restriction</TableCell>
              <TableCell>
                You have the right to request use to restrict how we use your
                information if you believe that:
                <ul>
                  <li>the information that we hold about you is inaccurate;</li>
                  <li>
                    we no longer need to use your information for the purposes
                    for whichit was provided, but you require the information to
                    establish, exercise or defend legal claims; or
                  </li>
                  <li>we are not using your information in a lawful manner.</li>
                </ul>
              </TableCell>
            </tr>
            <tr>
              <TableCell>Portability</TableCell>
              <TableCell>
                You have a right to receive any personal information you
                provided to us directly in an electronically and/or request that
                we send it to a third party, if technically feasible and secure.
                If you would like to do this please contact our support team at{' '}
                <a href="mailto:bosupport@natwest.com">BoSupport@natwest.com</a>
              </TableCell>
            </tr>
            <tr>
              <TableCell>Objection</TableCell>
              <TableCell>
                You have the right to object to how we are using your
                information for the purposes described in Schedule A, table (C),
                (which can be found at the end of this document), unless we can
                demonstrate overriding compelling and legitimate grounds for the
                processing or where we need to use your information to
                investigate and protect us or others from legal claims.
              </TableCell>
            </tr>
            <tr>
              <TableCell>Marketing</TableCell>
              <TableCell>
                You have a right to object to us using your personal information
                for direct marketing purposes, including profiling you for
                direct marketing. For more information see section 10.
              </TableCell>
            </tr>
            <tr>
              <TableCell>Lodge complaints</TableCell>
              <TableCell>
                <p>
                  If you want to make a complaint about how we have handled your
                  personal information, you can contact our Data Protection
                  Officer who will investigate the matter. Please contact us at
                  03457 242424 <b>Overseas Number</b> +44 131549 8888{' '}
                  <b>Minicom</b> 0800 404 6160.
                </p>
                <p>
                  We hope that we can address any concerns you may have, but if
                  you are not satisfied, you can always contact the Information
                  Commissioner’s Office (ICO). For more information, visit{' '}
                  <a href="https://ico.org.uk/" target="_blank">
                    https://ico.org.uk/
                  </a>
                  .
                </p>
              </TableCell>
            </tr>
          </table>
          <BoldText>5. Changing how we use your information</BoldText>
          <p>
            From time to time, we may change the way we use your information.
            Where we believe you may not reasonably expect such a change, we
            will give you at least 30 days notice to raise any objections before
            the change is made. However, in some cases, if you do not agree to
            such changes it may not be possible for us to continue to service
            your Bó account and/or provide certain products and services to you.
          </p>
          <BoldText>6. Who we share your information with</BoldText>
          <p>
            We will only use and share your information where it is necessary
            for us to lawfully carry out our business activities. Your
            information will be shared with other RBS group companies and there
            are some circumstances where we will also share your personal
            information with third parties outside of the RBS group including
            fraud prevention agencies, government entities and other third
            parties who we are required or permitted by law to disclose to. We
            will not share your information with anyone outside the RBS group
            except:
          </p>
          <ol>
            <li>where we have your permission;</li>
            <li>where required for your product or service;</li>
            <li>
              where we are required by law and by law enforcement agencies,
              judicial bodies, government entities, tax authorities or
              regulatory bodies around the world;
            </li>
            <li>
              with other banks, individuals or organisations so that we can help
              recover funds that have entered your account as a result of a
              payment sent in error by one of the above;
            </li>
            <li>
              with companies providing services to us, such as market analysis
              and benchmarking, correspondent banking, and agents and
              sub-contractors acting on our behalf, such as ID verification
              services;
            </li>
            <li>
              when you agree to receive marketing from us, we may use social
              media companies or other third party advertisers to display
              relevant messages to you about our products and services. Third
              party advertisers may also use information about your previous web
              activity to tailor adverts which are displayed to you;
            </li>
            <li>
              with other banks to help trace funds where you are a victim of
              suspected financial crime and you have agreed for us to do so, or
              where we suspect funds have entered your account as a result of a
              financial crime;
            </li>
            <li>with debt collection agencies;</li>
            <li>with credit reference and fraud prevention agencies;</li>
            <li>
              with external guarantors or other companies that provide you with
              benefits or services (such as insurance cover) associated with
              your product or service;
            </li>
            <li>
              where required for a proposed sale, reorganisation, transfer,
              financial arrangement, asset disposal or other transaction
              relating to our business and/or assets held by our business;
            </li>
            <li>
              in anonymised form as part of statistics or other aggregated data
              shared with third parties; or
            </li>
            <li>
              where necessary for our legitimate interests (e.g. to help us
              provide and improve our products and services to make them better
              for you) or those of a third party, and it is not inconsistent
              with the purposes listed above.
            </li>
          </ol>
          <p>
            If you ask us to, we will share information with any third party
            that provides you with account information or payment services. If
            you ask a third party provider to provide you with account
            information or payment services, you’re allowing that third party to
            access information relating to your account. We’re not responsible
            for any such third party’s use of your account information, which
            will be governed by their agreement with you and any privacy
            statement they provide to you.
          </p>
          <p>
            In the event that any additional authorised users are added to your
            account, we may share information about the use of the account by
            any authorised user with all other authorised users.
          </p>
          <p>
            Bó will not share your information with third parties for their own
            marketing purposes without your permission.
          </p>
          <BoldText>7. Transferring information overseas</BoldText>
          <p>
            We may transfer your information to organisations in other countries
            (including to other RBS group companies) on the basis that anyone to
            whom we pass it protects it in the same way we would and in
            accordance with applicable laws.
          </p>
          <p>
            In the event that we transfer information to countries outside of
            the European Economic Area (which includes countries in the European
            Union as well as Iceland, Liechtenstein and Norway), we will only do
            so where we are satisfied your information is adequately protected
            based on the European Commission’s assessment of the countries in
            question, the transfer has been authorised by the relevant data
            protection authority or a suitable contract with the organisation
            weare sharing your information with. You can contact us at{' '}
            <a href="mailto:bosupport@natwest.com">BoSupport@natwest.com</a>
            to get a copy of the relevant data protection clauses in the
            contract.
          </p>
          <BoldText>8. Marketing</BoldText>
          <p>
            When you have told us that you want to hear from us via the app, we
            may contact you from time to time about new products and services
            that we think could be of interest to you via email, text and other
            forms of communication. You can adjust your marketing preferences
            anytime you want in the app.
          </p>
          <BoldText>9. Communicating with you about your account</BoldText>
          <p>
            When we contact you we will mainly do so via the Bó app, but
            sometimes we may use email, text message, post and/or telephone. To
            help us get in touch with you please keep your contact details in
            the app up to date.
          </p>
          <p>
            We may monitor or record our communications with you in accordance
            with applicable laws for the purposes outlined in Schedule A.
          </p>
          <BoldText>
            10. Credit reference, fraud prevention and identification and
            verification partners
          </BoldText>
          <p>
            We may access and use information from credit reference and fraud
            prevention agencies when you open your account and periodically to:
          </p>
          <ol>
            <li>
              manage and take decisions about your accounts, including assessing
              your credit worthiness and checks to avoid you becoming
              over-indebted;
            </li>
            <li>prevent criminal activity, fraud and money laundering;</li>
            <li>
              check your identity and verify the accuracy of the information you
              provide to us; and
            </li>
            <li>trace debtors and recover debts.</li>
          </ol>
          <p>
            The decision to provide you with a Bó account may be taken based
            solely onautomated checks of information from credit reference and
            fraud prevention agencies and internal RBS group records. To help us
            make decisions on when togive you credit, we use a system called
            credit scoring to assess your application. To work out your credit
            score, we look at information you give us when you apply;
            information from credit reference agencies that will show us whether
            you’ve kept up to date with payments on any credit accounts (that
            could be any mortgages, loans, credit cards or overdrafts), or if
            you’ve had any court action such as judgments or bankruptcy; your
            history with us such as maximum level of borrowing; and
            affordability, by looking at your available net income and
            existingdebts. You have rights in relation to automated decision
            making, including a right to appeal the decision.
          </p>
          <p>
            We will continue to share information with credit reference agencies
            about how you manage your account including your account balance,
            payments into your account, the regularity of payments being made,
            credit limits and any arrears ordefault in making payments, while
            you have an account with us. This information will be made available
            to other organisations (including fraud preventionagencies and other
            financial institutions) so that they can take decisions about you,
            your associates and members of your household.
          </p>
          <p>
            If false or inaccurate information is provided and/or fraud is
            identified or suspected, details will be passed to fraud prevention
            agencies. Law enforcement agencies and other organisations may
            access and use this information. If we, or a fraud prevention
            agency, determine that you pose a fraud or money laundering risk, we
            and others may refuse to provide the services and financing you have
            requested, to employ you, or we may stop providing existing services
            to you.
          </p>
          <p>
            A record of any fraud or money laundering risk will be retained by
            the fraud prevention agencies, and may result in others refusing to
            provide services, financing or employment to you. Fraud prevention
            agencies can hold your information for different periods of time,
            and if you are considered to pose afraud or money laundering risk,
            your data can be held for up to six years.
          </p>
          <p>
            Please also be aware that, to make verifying your identity as part
            of the account opening process easy as possible, we will send
            required identification that you provide to us to a third party ID
            verification service provider.
          </p>
          <p>
            When the credit reference and fraud prevention agencies, and our
            identity and verification provider use your information, they do so,
            on the basis that they have a legitimate interest in preventing
            fraud and money laundering, and to verify identity, in order to
            protect their business and to comply with laws that apply to them.
            Further, they are independent controllers of your data when they use
            your personal information. If you want further details of how your
            information will be used, you can contact them using the relevant
            details below.
          </p>
          <table>
            <tr>
              <TableCell header>Credit reference agency</TableCell>
              <TableCell header>Contact details</TableCell>
            </tr>
            <tr>
              <TableCell>
                Equifax Limited{' '}
                <a href="https://www.experian.co.uk/crain" target="_blank">
                  www.experian.co.uk
                </a>
              </TableCell>
              <TableCell>
                <p>
                  Post: Equifax Ltd, Customer Service Centre PO Box 10036,
                  Leicester, LE3 4FS.
                </p>
                <p>
                  Website:{' '}
                  <a
                    href="https://www.equifax.co.uk/Contact-us/Contact_Us_Personal_Solutions.html"
                    target="_blank"
                  >
                    www.equifax.co.uk
                  </a>
                </p>
                <p>
                  Email:{' '}
                  <a href="mailto:www.equifax.co.uk/ask" target="_blank">
                    www.equifax.co.uk/ask
                  </a>
                </p>
                <p>Phone: 0333 321 4043 or 0800 014 2955</p>
              </TableCell>
            </tr>
            <tr>
              <TableCell>
                Experian Limited{' '}
                <a href="https://www.experian.co.uk/crain" target="_blank">
                  www.experian.co.uk
                </a>
              </TableCell>
              <TableCell>
                <p>Post: Experian, PO BOX 9000, Nottingham, NG807WF</p>
                <p>
                  Website:{' '}
                  <a
                    href="http://www.experian.co.uk/consumer/contact-us/index.html"
                    target="_blank"
                  >
                    www.experian.co.uk
                  </a>
                </p>
                <p>
                  Email:{' '}
                  <a href="mailto:consumer.helpservice@uk.experian.com">
                    consumer.helpservice
                  </a>
                </p>
                <p>Phone: 0344 481 0800 or 0800 013 8888</p>
              </TableCell>
            </tr>
            <tr>
              <TableCell header>Fraud prevention agency</TableCell>
              <TableCell header>Contact details</TableCell>
            </tr>
            <tr>
              <TableCell>
                Cifas{' '}
                <a href="https://www.cifas.org.uk" target="_blank">
                  https://www.cifas.org.uk
                </a>
              </TableCell>
              <TableCell>
                <p>
                  Post: Consumer Affairs, Cifas, 6th Floor, Lynton House, 7-12
                  Tavistock Square, London WC1H 9LT
                </p>
                <p>Phone: 0330 100 0180</p>
              </TableCell>
            </tr>
            <tr>
              <TableCell header>Identity & Verification</TableCell>
              <TableCell header>Contact details</TableCell>
            </tr>
            <tr>
              <TableCell>HooYu Limited</TableCell>
              <TableCell>
                <p>
                  Post: Quayside Lodge, William Morris Way, Fulham, London, SW6
                  2UZ
                </p>
                <p>
                  Website:{' '}
                  <a href="https://www.hooyu.com/" target="_blank">
                    https://www.hooyu.com/
                  </a>
                </p>
                <p>
                  Email:{' '}
                  <a href="mailto:support@hooyu.com">support@hooyu.com</a>
                </p>
                <p>Phone: 0207 909 2172</p>
              </TableCell>
            </tr>
          </table>
          <BoldText>11. How long we keep your information for</BoldText>
          <p>
            By providing you with products or services, we create records that
            contain your information, such as customer account records, activity
            records, tax records and lending and credit account records. Records
            can be held on a variety of media (physical or electronic) and
            formats.
          </p>
          <p>
            We manage our records to help us to serve our customers well (for
            example to help us deal with any queries you may have about your
            account) and to comply with legal and regulatory requirements.
            Records help us demonstrate that we are meeting our responsibilities
            and to keep as evidence of our business activities.
          </p>
          <p>
            How long we keep records depends on the type of record, the nature
            of the activity, product or service and the applicable local legal
            or regulatory requirements. We (and other RBS group companies)
            normally keep customer account records for up to six years after you
            close your account, whilst other records are retained for shorter
            periods. How long we retain your information for may change based on
            business or legal and regulatory requirements.
          </p>
          <p>
            We may in certain circumstances retain your information for longer
            periods, particularly where we need to withhold destruction or
            disposal based on an orderfrom the courts or an investigation by law
            enforcement agencies or our regulators. This is to make sure that we
            will be able to produce records as evidence, if they're needed.
          </p>
          <p>
            If you would like more information about how long we keep your
            information, please contact us at{' '}
            <a href="mailto:bosupport@natwest.com">BoSupport@natwest.com</a>
          </p>
          <BoldText>12. Security</BoldText>
          <p>
            At Bó, making sure that we and any third parties who act on our
            behalf keep yourpersonal information secure is really important to
            us.
          </p>
          <p>
            We will only use and share your information where it is necessary
            for us to carry out our lawful business activities. Your information
            may be shared with and used by other RBS group companies. We want to
            ensure that you fully understand how your information may be used.
            We have described what we may use your information for below:
          </p>
          <p>
            We may use your information where it is necessary to enter into a
            contract with you to provide you with our products or services or to
            perform our obligations under that contract. Without this
            information, we may not be able to continue to operate your Bó
            account and/or provide products and services to you. This may
            include processing to:
          </p>
          <table>
            <tr>
              <TableCell header>A. Contractual necessity</TableCell>
            </tr>
            <tr>
              <TableCell>
                <ol>
                  <li>
                    assess and process applications for products or services;
                  </li>
                  <li>
                    provide and administer those products and services
                    throughout your relationship with Bó, including opening,
                    setting up or closing your account or products, collecting
                    and issuing all necessary documentation, following your
                    instructions, processing transactions, including
                    transferring money between accounts, making payments to
                    third parties, resolving any queries or discrepancies and
                    administering any changes, including in relation to
                    Aggregated Accounts
                  </li>
                  <li>
                    manage and maintain our relationships with you and for
                    ongoing customer service. This may involve sharing your
                    information with other RBS group companies to improvethe
                    availability of our services;
                  </li>
                  <li>
                    administer any credit facilities or debts, including
                    agreeing repayment options; and
                  </li>
                  <li>
                    communicate with you about your account(s) or the products
                    and services you receive from us. Calls with our support
                    team and online communications may be recorded and monitored
                    for these purposes..
                  </li>
                </ol>
              </TableCell>
            </tr>
          </table>
          <table>
            <tr>
              <TableCell header>B. Legal obligation</TableCell>
            </tr>
            <tr>
              <TableCell>
                <p>
                  When you apply for a product or service (and throughout your
                  relationship with us), we are required by law to collect and
                  use certain personal information about you. Without this
                  information we may not be able to continue to operate your
                  account and/or provide products and services to you. This may
                  include processing to:
                </p>
                <ol>
                  <li>
                    confirm your identity, including using face-recognition
                    technology and other identification procedures, for example
                    fingerprint verification;
                  </li>
                  <li>
                    perform checks and monitor transactions and location data
                    for the purpose of preventing and detecting crime and to
                    comply with laws relating to money laundering, fraud,
                    terrorist financing, bribery and corruption, and
                    international sanctions. This may require us to use
                    information about criminal convictions and offences, to
                    investigate and gather intelligence on suspected financial
                    crimes, fraud and threats and to share data with law
                    enforcement and regulatory bodies;
                  </li>
                  <li>
                    assess affordability and suitability of credit for initial
                    credit applications and throughout the duration of the
                    relationship, including analysing customer credit data for
                    regulatory reporting;
                  </li>
                  <li>
                    share data with other banks and third parties to help
                    recover funds that have entered your account as a result of
                    a misdirected payment by such a third party;
                  </li>
                  <li>
                    share data with police, law enforcement, tax authorities or
                    other government and fraud prevention agencies where we have
                    a legal obligation, including reporting suspicious activity
                    and complying with production and court orders;
                  </li>
                  <li>
                    deliver mandatory communications to customers or
                    communicating updates to productand service terms and
                    conditions;
                  </li>
                  <li>investigate and resolve complaints;</li>
                  <li>
                    conduct investigations into breaches of conduct and
                    corporate policies by our employees;
                  </li>
                  <li>
                    manage contentious regulatory matters, investigations and
                    litigation;
                  </li>
                  <li>
                    perform assessments and analyse customer data for the
                    purposes of managing, improving and fixing data quality;
                  </li>
                  <li>
                    provide assurance that the bank has effective processes to
                    identify, manage, monitor and report the risks it is or
                    might be exposed to;
                  </li>
                  <li>
                    investigate and report on incidents or emergencies on bank’s
                    properties and premises; and
                  </li>
                  <li>
                    coordinate responses to business disrupting incidents and to
                    ensure facilities, systems and people are available to
                    continue providing services.
                  </li>
                </ol>
              </TableCell>
            </tr>
          </table>
          <table>
            <tr>
              <TableCell header>C. Legitimate interests of the bank</TableCell>
            </tr>
            <tr>
              <TableCell>
                <p>
                  We may use your information where it is in our legitimate
                  interests do so as an organisation and without prejudicing
                  your interests or fundamental rights and freedoms.
                </p>
                <p>
                  a) We may use your information in the day to day running of
                  our business, to manage our business and financial affairs and
                  to protect our customers and employees. It is in our interests
                  to ensure that our processes and systems operate effectively
                  and that we can continue operating as a business. This may
                  include processing your information to:
                </p>
                <ol>
                  <li>
                    monitor, maintain and improve internal business processes,
                    information and data,technology and communications solutions
                    and services;
                  </li>
                  <li>
                    ensure business continuity and disaster recovery and
                    responding to information technology and business incidents
                    and emergencies;
                  </li>
                  <li>
                    ensure network and information security, including
                    monitoring authorised users’ access to our information
                    technology for the purpose of preventing cyber-attacks,
                    unauthorised use of our telecommunications systems and
                    websites, prevention or detection of crime and protection of
                    your personal data;
                  </li>
                  <li>
                    provide assurance on our material risks and reporting to
                    internal management and supervisory authorities on whether
                    we are managing them effectively;
                  </li>
                  <li>
                    perform general, financial and regulatory accounting and
                    reporting;
                  </li>
                  <li>protect our legal rights and interests; and</li>
                  <li>
                    enable a sale, reorganisation, transfer or other transaction
                    relating to our business.
                  </li>
                </ol>
                <p>
                  b) It is in our interest as a business to ensure that we
                  provide you with the most appropriate products and services
                  and that we continually develop and improve as an
                  organisation. This may require processing your information to
                  enable us to:
                </p>
                <ol>
                  <li>
                    identify new business opportunities and to develop enquiries
                    and leads into applications or proposals for new business
                    and to develop our relationship with you;
                  </li>
                  <li>
                    send you relevant marketing information (including details
                    of other products or services provided by us or other RBS
                    group companies which we believe may be of interest to you).
                    We may show or send you marketing material online (on our
                    own and other websites including social media platforms), in
                    our app, or by email,sms or post;
                  </li>
                  <li>
                    understand your actions, behaviour, preferences,
                    expectations, feedback and financial history in order to
                    improve our products and services, offer you insights into
                    your spending and potential actions you could take and to
                    develop new products and services and to improve the
                    relevance of offers of products and services by RBS group
                    companies;
                  </li>
                  <li>
                    monitor the performance and effectiveness of products and
                    services;
                  </li>
                  <li>
                    assess the quality of our customer services and to provide
                    staff training. For these purposes we may record and monitor
                    our interactions with you;
                  </li>
                  <li>
                    perform analysis on customer complaints for the purposes of
                    preventing errors and process failures and rectifying
                    negative impacts on customers;
                  </li>
                  <li>
                    compensate customers for loss, inconvenience or distress as
                    a result of services, process or regulatory failures;
                  </li>
                  <li>
                    identify our customers’ use of third party products and
                    services in order to facilitate the uses of customer
                    information detailed above; and
                  </li>
                  <li>
                    combine your information with third party data, such as
                    economic data in order to understand customers’ needs better
                    and improve our services.
                  </li>
                </ol>
                <p>
                  We may perform data analysis, data matching and profiling to
                  support decision making with regards to the activities
                  mentioned above. It may also involve sharing information with
                  third parties who provide a service to us.
                </p>
                <p>
                  c) It is in our interest as a business to manage our risk and
                  to determine what products and services we can offer and the
                  terms of those products and services. It is also in our
                  interest to protect our business by preventing financial
                  crime. This may include processing your information to:
                </p>
                <ol>
                  <li>
                    carry out financial, credit and insurance risk assessments;
                  </li>
                  <li>manage and take decisions about your accounts;</li>
                  <li>
                    carry out checks (in addition to statutory requirements) on
                    customers and potential customers, business partners and
                    associated persons, including performing adverse media
                    checks, screening against external databases and sanctions
                    lists and establishing connections to politically exposed
                    persons;
                  </li>
                  <li>
                    share data with credit reference agencies, fraud prevention
                    agencies, law enforcement agencies and identification
                    verification service providers;
                  </li>
                  <li>
                    trace debtors and recovering outstanding debt; and for risk
                    reporting and risk management.
                  </li>
                </ol>
              </TableCell>
            </tr>
          </table>
        </TextContainer>
      </Wrapper>
    </Layout>
  );
};

export default PrivacyFull;
